import { ReactNode } from 'react'
import { useInView } from 'react-intersection-observer'
import { useMedia } from 'hooks/useMedia'
import { useSpring, a, easings } from '@react-spring/web'

type Props = {
  children: ReactNode
  customTrigger?: boolean
  className?: string
}

export const Shift = ({ children, customTrigger, className = '' }: Props) => {
  const isDesktop = useMedia('lg')
  const { ref: inViewRef, inView } = useInView({
    threshold: 0.35,
    triggerOnce: true,
  })
  const trigger = customTrigger ?? inView
  const shiftUp = useSpring({
    config: { mass: 1, easing: easings.easeInElastic },
    transform: trigger ? 'translateY(0px)' : isDesktop ? 'translateY(116px)' : 'translateY(65px)',
    opacity: trigger ? 1 : 0,
  })

  return (
    <a.div style={shiftUp} ref={inViewRef} className={className}>
      {children}
    </a.div>
  )
}
