import { useRef, useState, useEffect } from 'react'
import clsx from 'clsx'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination } from 'swiper'
import { NavigationOptions } from 'swiper/types'
import type { Swiper as SwiperType } from 'swiper'
import { ConfigurableProduct } from '@magentoTypes'
import { TeaserItem as TeaserItemType } from '@contentfulTypes'
import { Headline } from '~/elements/Headline/Headline'
import { useMedia } from '~/hooks/useMedia'
import { ProductCard } from '~/modules/PLP/components/ProductCard'
import { SlideNav } from '~/elements/SlideNav/SlideNav'
import { TeaserItem } from '~/elements/TeaserItem/TeaserItem'
import { FirstItemTeaser } from '~/modules/RecommendedProducts/components/FirstItemTeaser'
import { Shift } from '~/animations/Shift/Shift'
import { Media } from '~/lib/Media'
import dynamic from 'next/dynamic'

const LazyInViewProductRatingLoader = dynamic(
  () => import('../ClientReviews/components/InViewProductRatingLoader'),
  { ssr: false },
)

type Props = {
  products: ConfigurableProduct[]
  headlineText?: string
  h1Headline?: boolean
  lastItemTeaser?: Omit<TeaserItemType, 'contentfulMetadata'> | null | undefined
  firstItemTeaser?: Omit<TeaserItemType, 'contentfulMetadata'> | null | undefined
}

export const ProductsSlider = ({
  products,
  headlineText,
  h1Headline,
  lastItemTeaser,
  firstItemTeaser,
}: Props) => {
  const [isEnd, setIsEnd] = useState(false)
  const [isBeginning, setIsBeginning] = useState(false)
  const [firstTeaserIsVisible, setFirstTeaserIsVisible] = useState(true)
  const [opacityOneFirstSlider, setOpacityOneFirstSlider] = useState(true)
  const wrapper = useRef<HTMLElement>(null)
  const prevRef = useRef<HTMLButtonElement>()
  const nextRef = useRef<HTMLButtonElement>()
  const swiperRef = useRef<SwiperType>()
  const isDesktop = useMedia('lg')

  useEffect(() => {
    if (!isDesktop) return
    const scrollbarWidth = window.innerWidth - document.body.clientWidth
    if (wrapper.current) {
      wrapper.current.style.maxWidth = `calc(100vw - ${scrollbarWidth}px)`
    }
  }, [isDesktop])

  if (!products || !products.length) return null
  return (
    <section
      ref={wrapper}
      className="breakout-content-width relative box-border max-w-full overflow-hidden"
    >
      <LazyInViewProductRatingLoader />
      <div
        className={clsx(
          'flex px-4 pt-8 pb-6 lg:px-20 lg:pt-16 lg:pb-8',
          headlineText ? 'justify-between' : 'justify-end',
        )}
      >
        {headlineText ? (
          h1Headline ? (
            <Headline className="!font-dm-sans" type="h1" styleAs="h2">
              {headlineText}
            </Headline>
          ) : (
            <Headline type="h2">{headlineText}</Headline>
          )
        ) : null}
        <Media greaterThanOrEqual="lg">
          {isDesktop ? (
            <div>
              <SlideNav
                clickPrev={() => swiperRef?.current?.slidePrev()}
                clickNext={() => swiperRef?.current?.slideNext()}
                disablePrev={isBeginning}
                disableNext={isEnd}
                className="items-center"
              />
            </div>
          ) : null}
        </Media>
      </div>
      <Media greaterThanOrEqual="lg">
        {isDesktop ? (
          <Swiper
            modules={[Navigation, Pagination]}
            slidesPerView="auto"
            spaceBetween={0}
            speed={firstItemTeaser && firstTeaserIsVisible ? 1000 : 300}
            pagination={{
              clickable: !!isDesktop,
              renderBullet: function (index, className) {
                return '<span class="' + className + '"></span>'
              },
            }}
            onBeforeInit={(swiper) => {
              ;(swiper.params.navigation as NavigationOptions).prevEl = prevRef.current
              ;(swiper.params.navigation as NavigationOptions).nextEl = nextRef.current

              swiperRef.current = swiper
            }}
            onAfterInit={(swiper) => {
              setIsBeginning(swiper.isBeginning)
              setIsEnd(swiper.isEnd)
            }}
            onSlideChange={(swiper) => {
              setIsBeginning(swiper.isBeginning)
              setIsEnd(swiper.isEnd)

              if (swiper.activeIndex === 0) {
                setFirstTeaserIsVisible(true)
              } else {
                setFirstTeaserIsVisible(false)
              }

              if (swiper.activeIndex === 1) {
                setOpacityOneFirstSlider(false)
              } else {
                setOpacityOneFirstSlider(true)
              }
            }}
            navigation={{
              enabled: isDesktop === null ? false : isDesktop,
              nextEl: nextRef.current,
              prevEl: prevRef.current,
            }}
            className={clsx(
              // 'lg:h-[54vw] lgx:h-[44vw] xl:h-[47vw] 2xl:h-[42vw] 2k:h-[38vw]',
              firstTeaserIsVisible ? 'teaser-visible' : '',
            )}
          >
            {firstItemTeaser ? (
              <SwiperSlide
                className={clsx('mb-14 h-auto transition-all lg:!w-[44.2vw] lgx:!w-[36.3vw]')}
              >
                <Shift className="h-full overflow-hidden">
                  <FirstItemTeaser {...firstItemTeaser} isVisible={opacityOneFirstSlider} />
                </Shift>
              </SwiperSlide>
            ) : null}
            {products?.map((product) => (
              <SwiperSlide
                key={product?.sku}
                className={clsx('mb-14 h-auto lg:!w-[22.1vw] lgx:!w-[18.2vw]')}
              >
                <ProductCard
                  className="w-[250px] lg:w-full"
                  product={product as ConfigurableProduct}
                  productPrice={product as ConfigurableProduct}
                  hasBorderRight
                  hasBorderTop
                  isSlider
                />
              </SwiperSlide>
            ))}
            {lastItemTeaser ? (
              <SwiperSlide className="mb-14 h-auto lg:!w-[22.1vw] lgx:!w-[18.2vw]">
                <TeaserItem className="w-[250px] lg:w-full" {...lastItemTeaser} isInProductSlider />
              </SwiperSlide>
            ) : null}
          </Swiper>
        ) : null}
      </Media>
      <Media lessThan="lg">
        {isDesktop ? null : (
          <>
            {firstItemTeaser ? (
              <Shift>
                <FirstItemTeaser {...firstItemTeaser} />
              </Shift>
            ) : null}
            <div className="grid grid-flow-col justify-start overflow-x-auto">
              {products?.map((product) => (
                <ProductCard
                  className="w-[250px] lg:w-[262px]"
                  key={product?.sku}
                  product={product as ConfigurableProduct}
                  hasBorderRight
                  hasBorderTop
                  isSlider
                />
              ))}
              {lastItemTeaser ? <TeaserItem {...lastItemTeaser} isInProductSlider /> : null}
            </div>
          </>
        )}
      </Media>
    </section>
  )
}
