import clsx from 'clsx'
import { Image } from '~/elements/Image/Image'

export type Props = {
  clickPrev: () => void
  clickNext: () => void
  disablePrev?: boolean
  disableNext?: boolean
  className?: string
}

export const SlideNav = ({
  clickPrev,
  clickNext,
  disablePrev,
  disableNext,
  className = '',
}: Props) => {
  return (
    <div className={className}>
      <button
        type="button"
        onClick={clickPrev}
        className={clsx(
          'mr-2 inline-flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-grey-light xl:h-12 xl:w-12',
          disablePrev ? 'pointer-default pointer-events-none opacity-50' : '',
        )}
      >
        <Image
          src="/images/Icons/chevron-left.svg"
          width={24}
          height={24}
          placeholder="empty"
          alt="chevron-left-icon"
        />
      </button>
      <button
        type="button"
        onClick={clickNext}
        className={clsx(
          'inline-flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-grey-light xl:h-12 xl:w-12',
          disableNext ? 'pointer-default pointer-events-none opacity-50' : '',
        )}
      >
        <Image
          src="/images/Icons/chevron-right.svg"
          width={24}
          height={24}
          placeholder="empty"
          alt="chevron-icon"
        />
      </button>
    </div>
  )
}
