import clsx from 'clsx'
import { animated, useSpring } from '@react-spring/web'
import { useMedia } from '~/hooks/useMedia'
import { Headline } from '~/elements/Headline/Headline'
import { Image } from '~/elements/Image/Image'
import { ContentfulButton } from '~/elements/Button/ContentfulButton'
import { Icon } from '~/elements/Icon/Icon'

type Props = {
  headline?: string | null
  subline?: string | null
  className?: string
  cta?: any
  image?: any
  imageMobile?: any
  darkText?: boolean | null
  hasBackgroundGradient?: boolean | null
  isVisible?: boolean
  opacityIsReduced?: boolean
}

export const FirstItemTeaser = ({
  headline,
  subline,
  cta,
  className,
  image,
  imageMobile,
  darkText,
  hasBackgroundGradient,
  isVisible,
}: Props) => {
  const isDesktop = useMedia('lg')

  const changeWidthAnimation = useSpring({
    opacity: isVisible || !isDesktop ? 1 : 0,
    config: { tension: 50, friction: 9, duration: 800 },
  })

  return (
    <animated.div className={clsx('h-full', className)} style={changeWidthAnimation}>
      <div
        className={clsx(
          'absolute top-0 left-0 bottom-0 right-0 h-auto w-auto transition !duration-500 ease-out hover:scale-105',
        )}
      >
        <Image
          src={
            !isDesktop && imageMobile?.url ? (imageMobile?.url as string) : (image?.url as string)
          }
          alt={
            !isDesktop && imageMobile?.description
              ? (imageMobile?.description as string)
              : (image?.description as string)
          }
          fill
          style={{
            objectFit: 'cover',
            objectPosition: 'center',
          }}
          sizes={`(min-width: 1024px) 25vw, 100vw`}
        />
        {hasBackgroundGradient ? (
          <div
            className={clsx(
              'absolute top-0 left-0 bottom-0 right-0 bg-gradient-to-b from-[rgba(0,0,0,0)] to-[rgba(0,0,0,0.7)]',
            )}
          />
        ) : null}
      </div>
      <div className={clsx('relative h-auto w-full px-4 py-10 sm:px-10 lg:p-20')}>
        <Icon
          name="arrow-right"
          setHeight={isDesktop ? 60 : 40}
          setWidth={isDesktop ? 60 : 40}
          style={{
            color: 'white',
          }}
          className={clsx('mb-9 lg:mb-12')}
        />
        {headline ? (
          <Headline
            className={clsx('mb-4 font-dm-serif lg:mb-6', !darkText && '!text-primary-white')}
            type="h2"
          >
            {headline}
          </Headline>
        ) : null}
        {subline ? (
          <p
            className={clsx(
              'mb-6 max-w-lg text-h4-mobile font-medium lg:mb-8 lg:text-h4-desktop',
              !darkText && '!text-primary-white',
            )}
          >
            {subline}
          </p>
        ) : null}
        {cta ? (
          <ContentfulButton
            {...cta}
            ctaStyle="MediumText"
            textLinkIsWhite={!darkText}
            className="block w-full"
          />
        ) : null}
      </div>
    </animated.div>
  )
}
